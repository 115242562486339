// vue
import { computed, ref, watch } from 'vue'

// pinia & stores
import { defineStore, storeToRefs } from 'pinia'
import { useTranscriptStore } from '@/store/transcript'
// types
import { Nullable } from '@revolutionprep/types'
import { Annotation, AnnotationComponentName } from '@/types/annotation'

// composables
import { useStorage, WatcherGenerator } from '@/composables/storage'

export const useAnnotationStore = defineStore('annotation', () => {
  // state
  const activeAnnotatorInstance = ref<Nullable<any>>(null)
  const annotations = ref<Annotation[]>([])
  const annotatorInstances = ref<Array<any>>([])
  const isHighlightEnabled = ref(false)
  const isAnnotationNew = ref(false)
  const isLoadingPendingAnnotation = ref(false)
  const pendingAnnotation = ref<Nullable<Annotation>>(null)
  const pendingAnnotatorInstance = ref<Nullable<any>>(null)
  const selectedAnnotation = ref<Nullable<Annotation>>(null)
  const selectedText = ref('')
  const typeOfCurrentSelection = ref<'Caret' | 'Range'>('Caret')

  // composables
  const storage = useStorage()

  // stores
  const transcriptStore = useTranscriptStore()
  const { activeQuestionIndex } = storeToRefs(transcriptStore)

  // watchers
  const statePropsToWatch: WatcherGenerator[] = [
    {
      propertyName: 'annotations',
      valueToWatch: annotations
    },
    {
      propertyName: 'isHighlightEnabled',
      valueToWatch: isHighlightEnabled
    }
  ]
  storage.doGenerateStateWatchers(statePropsToWatch, 'annotation')

  watch(activeQuestionIndex, () => {
    // when user navigates to another question
    // reset pending annotation states
    pendingAnnotation.value = null
    pendingAnnotatorInstance.value = null
  })

  // private methods
  function _doFindAnnotationIndex (annotation: Annotation) {
    return annotations.value.findIndex(
      (_annotation) => _annotation.id === annotation.id
    )
  }

  // public methods
  function doCreateOrUpdateAnnotation (
    annotation: Annotation,
    annotatorInstance: any
  ) {
    if (!annotatorInstance) {
      return Promise.reject(
        new Error('Annotator instance is undefined')
      )
    }
    // let's try to find the annotation index
    const index = _doFindAnnotationIndex(annotation)
    activeAnnotatorInstance.value = annotatorInstance
    if (index !== -1) {
      // if we found one, let's update it
      annotations.value[index] = annotation
    } else {
      // otherwise let's create new annotation
      annotations.value.push(annotation)
    }
    selectedAnnotation.value = annotation
    annotatorInstance.addAnnotation(annotation)
    return Promise.resolve()
  }

  function doRemoveAnnotation (
    annotation: Annotation,
    annotatorInstance: any
  ) {
    if (!annotatorInstance) {
      return Promise.reject(
        new Error('Annotator instance is undefined')
      )
    }

    // try to find item index in the annotation list
    const index = _doFindAnnotationIndex(annotation)
    if (index === -1) {
      return Promise.reject(
        new Error('Unable to find annotation index')
      )
    }
    activeAnnotatorInstance.value = annotatorInstance
    annotatorInstance.removeAnnotation(annotation)
    annotations.value.splice(index, 1)
    doResetAnnotationState()
    return Promise.resolve()
  }

  function doResetAnnotationState () {
    activeAnnotatorInstance.value = null
    selectedAnnotation.value = null
    selectedText.value = ''
  }

  function doFilterAnnotationsByComponentName (
    annotations: Annotation[],
    componentName: AnnotationComponentName
  ) {
    return annotations.filter(
      (annotation) => {
        return annotation.component === componentName
      }
    )
  }

  function doFilterAnnotationsByTranscriptDetailId (
    annotations: Annotation[],
    transcriptDetailId: number
  ) {
    return annotations.filter((annotation: Annotation) => {
      return annotation.transcriptDetailId === transcriptDetailId
    })
  }

  return {
    activeAnnotatorInstance,
    annotations,
    annotatorInstances,
    doCreateOrUpdateAnnotation,
    doFilterAnnotationsByComponentName,
    doFilterAnnotationsByTranscriptDetailId,
    doRemoveAnnotation,
    doResetAnnotationState,
    isAnnotationNew,
    isHighlightEnabled,
    isLoadingPendingAnnotation,
    pendingAnnotation,
    pendingAnnotatorInstance,
    selectedAnnotation,
    selectedText,
    typeOfCurrentSelection
  }
})
