// ionic + vue
import { modalController } from '@ionic/vue'
import { ref } from 'vue'

// pinia
import { defineStore } from 'pinia'

// composables
import { useStorage, WatcherGenerator } from '@/composables/storage'

export const useGlobalStore = defineStore('global', () => {
  // composables
  const storage = useStorage()

  // state
  const isCrossOutEnabled = ref(false)
  const isLoadingNextModule = ref(false)
  const isTimerPaused = ref(false)

  // modal state
  const showModalAnnotate = ref(false)
  const showModalBreak = ref(false)
  const showModalCalculator = ref(false)
  const showModalExitExam = ref(false)
  const showModalHelp = ref(false)
  const showModalNoAnswers = ref(false)
  const showModalQuestionNavigation = ref(false)
  const showModalReferenceSheet = ref(false)
  const showModalReturnHome = ref(false)

  // popover state
  const showPopoverDirections = ref(false)
  const showPopoverReportBug = ref(false)

  // test day mode
  const isTestDayModeEnabled = ref(true)
  const showPracticeModeToggle = ref(false)

  // watchers
  const statePropsToWatch: WatcherGenerator[] = [
    {
      propertyName: 'isCrossOutEnabled',
      valueToWatch: isCrossOutEnabled
    },
    {
      propertyName: 'isTestDayModeEnabled',
      valueToWatch: isTestDayModeEnabled
    },
    {
      propertyName: 'isTimerPaused',
      valueToWatch: isTimerPaused
    },
    {
      propertyName: 'showPracticeModeToggle',
      valueToWatch: showPracticeModeToggle
    }
  ]
  storage.doGenerateStateWatchers(statePropsToWatch, 'global')

  // actions
  async function doCloseAllModalsAndPopovers () {
    const modalAndPopoverStates = [
      showModalAnnotate,
      showModalBreak,
      showModalCalculator,
      showModalExitExam,
      showModalHelp,
      showModalQuestionNavigation,
      showModalReferenceSheet,
      showModalReturnHome,
      showPopoverDirections,
      showPopoverReportBug
    ]

    modalAndPopoverStates.forEach((componentState) => {
      componentState.value = false
    })

    const openedModal = await modalController.getTop()
    if (openedModal) {
      await modalController.dismiss()
    }
  }

  return {
    doCloseAllModalsAndPopovers,
    isCrossOutEnabled,
    isLoadingNextModule,
    isTestDayModeEnabled,
    isTimerPaused,
    showModalAnnotate,
    showModalBreak,
    showModalCalculator,
    showModalExitExam,
    showModalHelp,
    showModalNoAnswers,
    showModalQuestionNavigation,
    showModalReferenceSheet,
    showModalReturnHome,
    showPopoverDirections,
    showPopoverReportBug,
    showPracticeModeToggle
  }
})
