// ionic + vue
import { IonicVue } from '@ionic/vue'
import { createApp } from 'vue'

// router
import router from '@/router'

// pinia + stores
import { createPinia, storeToRefs } from 'pinia'
import { useAuthStore } from '@/store/auth'
import { useErrorStore } from '@/store/error'
import { useStorageStore } from '@/store/storage'

// composables
import { useSentry } from '@/composables/sentry'

// plugins
import resetStore from '@/plugins/pinia/reset-store'
import repository from '@/plugins/repository'

// pwa - service worker
import './registerServiceWorker'

// vue app component
import App from '@/App.vue'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/digital-act.css'
import './theme/digital-sat.css'

/* Global style overrides */
import './theme/overrides.css'

/* VueTippy Theme Styles */
import 'tippy.js/dist/tippy.css'

const pinia = createPinia()
pinia.use(resetStore)

const app = createApp(App)
  .use(IonicVue, {
    mode: 'md'
  })
  .use(pinia)

const storageStore = useStorageStore()
await storageStore.doInitStorage()

const authStore = useAuthStore()
await authStore.doLogin()

  app.use(router)
     .use(repository)

// initialize Sentry
const { doCaptureException, doInitSentry } = useSentry(app, router)
doInitSentry()

router.isReady().then(() => {
  app.mount('#app')
})

const errorStore = useErrorStore()
const { latestError } = storeToRefs(errorStore)

app.config.errorHandler = (err: unknown) => {
  doCaptureException(err)
  // set error in pinia error store
  latestError.value = err
  // redirect to '/error' route
  router.replace({
    name: 'Error'
  })
}
